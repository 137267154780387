import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

/** ******************************* 懒加载路由 *********************************/
// 默认布局 有头尾
const defaultLayout = () =>
  import(/* webpackChunkName: "layout" */ '@/layout/Default.vue')
// 登录页布局
const loginLayout = () =>
  import(/* webpackChunkName: "layout" */ '@/layout/LoginLayout.vue')

// 首页
const home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
// 组件测试页面
const componentsTest = () => import(/* webpackChunkName: "componentsTest" */ '@/views/ComponentsTest.vue')
// 登录
const login = () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue')
// 忘记密码
const forgotpassword = () => import(/* webpackChunkName: "login" */ '@/views/login/ForgotPassword.vue')
// 登记信息首页
const register = () => import(/* webpackChunkName: "register" */ '@/views/register/Index.vue')
// 登记信息-Eco Partner Registration Form
const registerEco = () => import(/* webpackChunkName: "register" */ '@/views/register/Eco.vue')
// 登记信息-DHOP Partner Registration Form
const registerDhop = () => import(/* webpackChunkName: "register" */ '@/views/register/Dhop.vue')
// 登记信息-Synergy Partner Registration Form
const registerSynergy = () => import(/* webpackChunkName: "register" */ '@/views/register/Synergy.vue')
// 个人中心
const myPage = () => import(/* webpackChunkName: "myPage" */ '@/views/myPage/Index.vue')
const myPageCompany = () => import(/* webpackChunkName: "myPage" */ '@/views/myPage/Company.vue')
const myPageSolution = () => import(/* webpackChunkName: "myPage" */ '@/views/myPage/Solution.vue')
const myPageSolutionEdit = () => import(/* webpackChunkName: "myPage" */ '@/views/myPage/SolutionEdit.vue')
const myPageSolutionInReview = () => import(/* webpackChunkName: "myPage" */ '@/views/myPage/InReview.vue')
const myPageMyProfile = () => import(/* webpackChunkName: "myPage" */ '@/views/myPage/MyProfile.vue')
// 集成协议
const integration = () => import(/* webpackChunkName: "integration" */ '@/views/integration/Index.vue')
const integrationProduct = () => import(/* webpackChunkName: "integration" */ '@/views/integration/Product.vue')
const integrationGuide = () => import(/* webpackChunkName: "integration" */ '@/views/integration/Guide.vue')
const integrationGuideDownload = () => import(/* webpackChunkName: "integration" */ '@/views/integration/Download/Index.vue')
const integrationOverview = () => import(/* webpackChunkName: "integration" */ '@/views/integration/Overview.vue')
// 解决方案
const solutions = () => import(/* webpackChunkName: "solutions" */ '@/views/solutions/Index.vue')
const solutionsInfo = () => import(/* webpackChunkName: "solutions" */ '@/views/solutions/Introduction.vue')
// 合作伙伴
const partners = () => import(/* webpackChunkName: "integration" */ '@/views/partners/Index.vue')
const partnersList = () => import(/* webpackChunkName: "integration" */ '@/views/partners/List.vue')
const partnersIntroduction = () => import(/* webpackChunkName: "integration" */ '@/views/partners/Introduction.vue')
// partnerProgram
const partnerProgram = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/Index.vue')
const ECOPartnerProgram = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/ECOPartnerProgram.vue')
const synergyPartnerProgram = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/SynergyPartnerProgram.vue')
const DHOPPartnerProgram = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/DHOPPartnerProgram.vue')
const applicationMarketplace = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/ApplicationMarketplace.vue')
const subPage = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/SubPage.vue')
const cantactForm = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/CantactForm.vue')
const DHOPManager = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/dhopManager/Index.vue')
const SDKDownload = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/dhopManager/SDKDownload.vue')
const Debug = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/dhopManager/Debug')
const LicenseInfo = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/dhopManager/licenseInfo/Index')
const CreateLicense = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/dhopManager/licenseInfo/CreateLicense')
const KeyInfo = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/dhopManager/keyInfo/Index')
const CreateKey = () => import(/* webpackChunkName: "partnerProgram" */ '@/views/partnerProgram/dhopManager/keyInfo/CreateKey')
// events
const events = () => import(/* webpackChunkName: "events" */ '@/views/events/Index.vue')
const eventsWebinars = () => import(/* webpackChunkName: "events" */ '@/views/events/Webinars.vue')
const eventsDetail = () => import(/* webpackChunkName: "events" */ '@/views/events/Detail.vue')
// support
const getSupport = () => import(/* webpackChunkName: "support" */ '@/views/support/GetSupport.vue')
const questionDetail = () => import(/* webpackChunkName: "support" */ '@/views/support/QuestionDetail.vue')

// 404页面
const NotFound = () => import('@/views/errorPage/404')

/** ********* 静态路由 ***********/
export const staticRoutes = [
  {
    path: '/login',
    component: loginLayout,
    children: [
      {
        path: '/',
        name: 'login',
        component: login
      },
      {
        path: 'forgotpassword',
        name: 'forgotpassword',
        component: forgotpassword
      }
    ]
  },
  {
    path: '/',
    component: defaultLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: home
      },
      {
        path: 'componentsTest',
        name: 'componentsTest',
        component: componentsTest
      },
      {
        path: 'register',
        name: 'register',
        component: register
      },
      {
        path: 'register/eco',
        name: 'registerEco',
        component: registerEco
      },
      {
        path: 'register/dhop',
        name: 'registerDhop',
        component: registerDhop
      },
      {
        path: 'register/synergy',
        name: 'registerSynergy',
        component: registerSynergy
      },
      {
        path: 'mypage',
        name: 'mypage',
        component: myPage,
        meta: {
          auth: true // 需要登录后访问的页面，添加该字段
        }
      },
      {
        path: 'mypage/company',
        name: 'mypagecompany',
        component: myPageCompany,
        meta: {
          auth: true
        }
      },
      {
        path: 'mypage/solution',
        name: 'mypagesolution',
        component: myPageSolution,
        meta: {
          auth: true
        }
      },
      {
        path: 'mypage/solution/edit/:id?',
        name: 'mypagesolutionedit',
        component: myPageSolutionEdit,
        meta: {
          auth: true
        }
      },
      {
        path: 'mypage/solution/inreview',
        name: 'mypagesolutioninreview',
        component: myPageSolutionInReview,
        meta: {
          auth: true
        }
      },
      {
        path: 'mypage/profile',
        name: 'myPageMyProfile',
        component: myPageMyProfile
      },
      {
        path: 'integration',
        name: 'integration',
        component: integration
      },
      {
        path: 'integration/product',
        name: 'integrationproduct',
        component: integrationProduct
      },
      {
        path: 'integration/guide',
        name: 'integrationguide',
        component: integrationGuide
      },
      // integrationGuideDetail
      {
        path: 'integration/guide/download/:name',
        name: 'integrationGuideDownload',
        component: integrationGuideDownload
      },
      {
        path: 'integration/overview',
        name: 'integrationoverview',
        component: integrationOverview
      },
      {
        path: 'solutions_services',
        name: solutions,
        component: solutions
      },
      {
        path: 'solutions_services/introduction/:id',
        name: solutionsInfo,
        component: solutionsInfo
      },
      {
        path: 'partners',
        name: 'partners',
        component: partners
      },
      {
        path: 'partners/list',
        name: 'partnerslist',
        component: partnersList
      },
      {
        path: 'partners/introduction',
        name: 'partnersintroduction',
        component: partnersIntroduction
      },
      {
        path: 'partnerPrograms',
        name: 'partnerProgram',
        component: partnerProgram
      },
      {
        path: 'partnerPrograms/ECOPartnerProgram',
        name: 'ECOPartnerProgram',
        component: ECOPartnerProgram
      },
      {
        path: 'partnerPrograms/synergyPartnerProgram',
        name: 'synergyPartnerProgram',
        component: synergyPartnerProgram
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram',
        name: 'DHOPPartnerProgram',
        component: DHOPPartnerProgram
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/applicationMarketplace',
        name: 'applicationMarketplace',
        component: applicationMarketplace
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/applicationMarketplace/subPage',
        name: 'subPage',
        component: subPage
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/applicationMarketplace/subPage/cantactForm',
        name: 'cantactForm',
        component: cantactForm
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/DHOPManager',
        name: 'DHOPManager',
        component: DHOPManager
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/DHOPManager/SDKDownload',
        name: 'SDKDownload',
        component: SDKDownload
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/DHOPManager/Debug',
        name: 'Debug',
        component: Debug
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/DHOPManager/LicenseInfo',
        name: 'LicenseInfo',
        component: LicenseInfo
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/DHOPManager/LicenseInfo/CreateLicense',
        name: 'CreateLicense',
        component: CreateLicense
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/DHOPManager/KeyInfo',
        name: 'KeyInfo',
        component: KeyInfo
      },
      {
        path: 'partnerPrograms/DHOPPartnerProgram/DHOPManager/KeyInfo/CreateKey',
        name: 'CreateKey',
        component: CreateKey
      },
      {
        path: 'events',
        name: 'events',
        component: events
      },
      {
        path: 'events/webinars',
        name: 'webinars',
        component: eventsWebinars
      },
      {
        path: 'events/webinars/detail',
        name: 'eventsDetail',
        component: eventsDetail
      },
      {
        path: 'support/getSupport',
        name: 'getSupport',
        component: getSupport,
        meta: {
          auth: true
        }
      },
      {
        path: '/support/get_support_question',
        name: 'questionDetail',
        component: questionDetail,
        meta: {
          auth: true
        }
      },
      {
        path: '/notFound',
        name: 'notFound',
        component: NotFound
      },
      {
        path: '*',
        name: '404',
        component: NotFound
      }
    ]
  }
]

/** ********* 路由初始化 ***********/
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...staticRoutes],
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

/** ********* 点击重复路由报错问题 ***********/
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

/** ********* 全局路由导航守卫 ***********/
router.beforeEach(async (to, from, next) => {
  // 如果语言包没存储过，则存储
  if (!Object.keys(store.state.common.LP).length) {
    await store.dispatch('common/getLanguagePack')
    // 绑定语言包至原型，这样不用每个页面再引入了
    Vue.prototype.LP = store.state.common.LP
  }
  // 如果目标页面需要登录在能访问，则判断token是否存在。不存在则跳转到首页
  if (to.meta.auth) {
    if (!store.state.login.token) {
      Vue.prototype.$message.error(Vue.prototype.LP.lang_login_expired)
      next({ path: '/login', replace: true })
    }
  }
  next()
})

export default router
