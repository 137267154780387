<template>
  <div class="public-title container">
    <h2
      v-if="title"
      class="title"
      :class="{
        'is-white': isWhiteText,
        'is-bord': isBordText
      }"
    >{{ title }}</h2>
    <h3 v-if="subtitle" class="subtitle">{{ subtitle }}</h3>
  </div>
</template>

<script>
export default {
  name: 'PublicTitle',
  props: {
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 副标题
    subtitle: {
      type: String,
      default: ''
    },
    // 是否是白色文字
    isWhiteText: {
      type: Boolean,
      dafault: false
    },
    // 是否加粗
    isBordText: {
      type: Boolean,
      dafault: false
    }
  }
}
</script>

<style lang="less" scoped>
.public-title {
  text-align: center;
  .title {
    font-size: 42px;
    font-weight: 400;
    color: #303133;
    line-height: 56px;
    margin-bottom: 24px;
    &.is-white {
      color: #FFFFFF;
    }
    &.is-bord {
      font-weight: bold;
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    color: #333333;
    line-height: 21px;
    margin-top: 48px;
  }
  @media screen and (max-width: 1024px) {
    .title {
      font-size: 28px;
      line-height: 48px;
    }
  }
}
</style>
