import Vue from 'vue'
import Vuex from 'vuex'

// 通过webpack中的require.context方法创建一个context module, 接收三个参数（必须是字面量，不能用形参）：文件夹路径，是否搜索子目录，文件匹配正则
const files = require.context('./modules', false, /\.js$/)
// 将context module通过keys方法，返回每个请求（路径）
const modules = files.keys().reduce((object, filePath) => {
  // context module会导出一个require函数，可以接收一个请求（路径）
  const fileConfig = files(filePath)
  // 文件名，作为导出对象的key值
  const fileName = filePath
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')
  // 拼接参数 如果是通过 `export default` 导出的优先使用 `.default`，否则回退到使用模块的根
  object[fileName] = fileConfig.default || fileConfig
  return object
}, {})

Vue.use(Vuex)

export default new Vuex.Store({
  modules
})
