import { getLanguageApi, getBannerApi } from '@/api/home'
// import { getLanguageApi } from '@/api/home'

const state = {
  meta: {
    title: '海外DEPP网站',
    description: '',
    keywords: ''
  },
  // 语言包
  LP: {},
  // banner 图片
  bannerInfo: {
    img: ''
  }
}

const getters = {
  meta: (state) => state.meta
}

const actions = {
  // 获取语言包
  async getLanguagePack({ commit }) {
    const data = await getLanguageApi()
    if (data.status === '00') {
      commit('SET_LANGUAGE_PACK', Object.freeze(data.data))
    }
  },
  // 获取banner图片
  async getBannerImg({ commit }, obj) {
    const data = await getBannerApi({ banner_id: obj.num })
    if (data.status === '00') {
      commit('SET_BANNER_IMG', data.data)
    }
    return data
  }
}

const mutations = {
  SET_META: (state, params) => {
    state.meta.title = params.title
    state.meta.description = params.description
    state.meta.keywords = params.keywords
  },
  SET_LANGUAGE_PACK: (state, params) => {
    state.LP = params
  },
  SET_BANNER_IMG: (state, params) => {
    state.bannerInfo.img = params.img_image
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
