<template>
  <div class="newYear">
    <el-dialog :visible="newYearDialog" width="48%" :before-close="handleClose">
      <img :src="url" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import newYear from "@/assets/img/happyNewYear/newYear.png";

export default {
  data() {
    return {
      newYearDialog: false,
      url: newYear,
    };
  },
  watch: {
    newYearDialog(val, oldVal) {
      if (val) {
        setTimeout(() => {
          this.handleClose();
        }, 15000);
      }
    },
    deep: true,
    immediate: true,
  },
  methods: {
    openDialog() {
      this.newYearDialog = true;
      sessionStorage.setItem("happyNewYear", new Date().toLocaleDateString());
    },
    handleClose() {
      this.newYearDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.newYear {
  img {
    max-width: 1000px;
    width: 100%;
  }
}
</style>
<style lang="less">
.newYear {
  .el-dialog {
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .el-dialog__wrapper {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .el-dialog__headerbtn {
    top: 44px;
    right: 6px;
    font-size: 22px;
    background: #fff;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
  @media screen and (max-width: 1400px) {
    .el-dialog {
      width: 70% !important;
    }
  }
  @media screen and (max-width: 992px) {
    .el-dialog {
      width: 80% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .el-dialog {
      width: 100% !important;
    }
  }
}
</style>