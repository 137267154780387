import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  header: '/home/header', // 头部导航
  footer: '/home/footer', // 底部导航
  home: '/home/index', // 首页数据
  language: '/home/language_web', // 语言包
  banner: '/home/banner' // banner图
}

// 头部导航
export const getHeaderApi = () => _axios.get(apiUrl.header)
// 底部导航
export const getFooterApi = () => _axios.get(apiUrl.footer)
// 首页数据
export const getHomeApi = () => _axios.get(apiUrl.home)
// 语言包
export const getLanguageApi = () => _axios.get(apiUrl.language)
// banner图
export const getBannerApi = (params) => _axios.get(apiUrl.banner + `?banner_id=${params.banner_id}`)
