import jsCookie from 'js-cookie'

// 存cookie
export const setCookie = (key, value, hours) => jsCookie.set(key, value, { expires: hours / 24 })
// 获取cookie
export const getCookie = (key) => jsCookie.get(key)
// 获取JSON cookie
export const getJsonCookie = (key) => jsCookie.getJSON(key)
// 清空cookie
export const removeCookie = (key) => jsCookie.remove(key)
