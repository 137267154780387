import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'
const isPord = process.env.NODE_ENV === 'production' // 是否为生产环境

// axios基础配置
const config = {
  withCredentials: true, // cookie
  timeout: 60000,
  baseURL: isPord ? '/depp' : 'http://www2.dahuasecurity.com:8083/depp'
}
// 创建axios实例
export const _axios = axios.create(config)

// 请求拦截
_axios.interceptors.request.use(
  config => {
    if (config.method === 'post' && !config.isFormData) {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
_axios.interceptors.response.use(
  res => {
    if (res.data.status === '00' || res.data.status === '41' || res.data.status === '43') {
      return res.data
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    // token过期状态码退出登录
    if (error?.response?.data?.status === '41') {
      store.dispatch('login/logout').then(res => {
        Vue.prototype.$message.error(Vue.prototype.LP.lang_login_expired)
        router.replace('/login')
      })
    }
    // 接口cancel类型错误
    if (axios.isCancel(error)) {
      return Promise.reject({
        type: 'cancel'
      })
    } else {
      return Promise.reject(error)
    }
  }
)

