import { getLoginApi, logoutApi } from '@/api/login'
import { setCookie, removeCookie, getCookie } from '@/utils/cookie'

const state = {
  token: getCookie('token') || '' // token
}

const getters = {}

const actions = {
  // 登录获取token
  async getLogin({ commit }, params) {
    const data = await getLoginApi(params)
    // 将token存入cookie
    if (data.status === '00') {
      setCookie('token', data.data.token, 12)
      setCookie('email', data.data.email, 12)
      setCookie('isShow', data.data.is_show, 12)
      commit('SET_LOGIN', data.data.token)
    }
    return data
  },
  // 退出登录
  async logout({ commit }) {
    logoutApi().catch(err => err)
    commit('SET_LOGIN', '')
    removeCookie('token')
    removeCookie('email')
    removeCookie('isShow')
    return Promise.resolve()
  }
}

const mutations = {
  // token
  SET_LOGIN(state, data) {
    state.token = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
