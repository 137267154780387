import { getUserInfoApi } from '@/api/login'

const state = {
  userInfo: '' // 用户信息
}

const getters = {}

const actions = {
  // 获取用户信息
  async getUserInfo({ commit }) {
    const { data } = await getUserInfoApi({type:1})
    commit('SET_USER_INFO', data)
  }
}

const mutations = {
  // 获取用户信息
  SET_USER_INFO(state, data) {
    state.userInfo = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
