import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  login: '/user/login', // 登录
  logout: '/user/logout', // 退出登录
  code: '/user/verification_code', // 发送验证码
  changePassword: '/user/forget_change_password', // 修改密码
  userInfo: '/user/user_info', // 获取用户信息
  emailVerify: '/user/email_verification_code' // 邮箱图片验证
}

// 头部导航
export const getLoginApi = (params) => _axios.post(apiUrl.login, params)
// 发送验证码
export const getCodeApi = (params) => _axios.post(apiUrl.code, params)
// 修改密码
export const getChangePasswordApi = (params) => _axios.post(apiUrl.changePassword, params)
// 根据token获取用户信息
export const getUserInfoApi = (params) => _axios.get(apiUrl.userInfo + `?type=${params.type}`)
// 退出登录
export const logoutApi = () => _axios.get(apiUrl.logout)
// 邮箱图片验证
export const emailVerifyApi = (params) => _axios.post(apiUrl.emailVerify, params)

