import Vue from 'vue'
import ElementUI from 'element-ui'
import { Message } from 'element-ui'
import preview from 'vue-photo-preview'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import './plugins/element.js'
import '@/styles/common/reset.less'
import '@/styles/common/common.less'
import '@/styles/common/elementReset.less'
import '@/assets/iconfont/iconfont.js'
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfontNew/iconfont.js'
import '@/assets/iconfontNew/iconfont.css'
import 'vue-photo-preview/dist/vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import locale from 'element-ui/lib/locale/lang/en'

import newYearDialog from '@/components/newYear/newYearDialog'
import PublicTitle from '@/components/public/PublicTitle'
// Vue.component('ss', PublicTitle)
PublicTitle.install = function(Vue) {
  Vue.component('ss', PublicTitle)
}
Vue.use(PublicTitle)
Vue.use(preview)
Vue.use(ElementUI, { locale })

// if (!sessionStorage.getItem('happyNewYear')) {
//   // 设置新年弹窗提醒
//   const messageBox = Vue.extend(newYearDialog)
//   newYearDialog.install = function() {
//     // 使用$mount挂载
//     let instance = new messageBox({}).$mount()
//     // 将组件插入页面
//     document.body.appendChild(instance.$el)

//     Vue.nextTick(() => {
//       // 控制弹窗为打开状态
//       instance.openDialog()
//     })
//   }()
// }

// 分别对success、warning和error等样式进行设置
// message: 为提示信息, duration: 为显示时长, showClose: 为是否展示关闭按钮(可设置动态,默认为false)
Vue.prototype.$message.success = function (msg, isShow) {
  return Message.success({
    message: msg,
    duration: 1500,
    showClose: isShow
  })
}
Vue.prototype.$message.warning = function (msg, isShow) {
  return Message.warning({
    message: msg,
    duration: 1500,
    showClose: isShow
  })
}
Vue.prototype.$message.error = function (msg, isShow) {
  return Message.error({
    message: msg,
    duration: 1500,
    showClose: isShow
  })
}

Vue.config.productionTip = false
Vue.use(Meta)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
