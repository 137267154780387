import Vue from 'vue'

import {
  Pagination,
  Form,
  FormItem,
  Input,
  Button,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Radio,
  RadioGroup,
  Dialog,
  Message,
  Loading,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Upload,
  popover,
  Collapse,
  CollapseItem,
  MessageBox,
  Table,
  TableColumn
} from 'element-ui'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

const components = {
  Pagination,
  Form,
  FormItem,
  Input,
  Button,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Radio,
  RadioGroup,
  Dialog,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Upload,
  popover,
  Collapse,
  CollapseItem,
  Table,
  TableColumn
}

for (const component in components) {
  Vue.use(components[component])
}

Vue.use(Loading.directive)
Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert
Vue.component(CollapseTransition.name, CollapseTransition)

